import React from 'react'
import dietImg from '../img/mad-dogs-sidecar.webp'
import Model from './Model'
function LifeHistory() {

  const backgroundImageStyle={
    backgroundImage: 
    `url(${dietImg})`,
    height:'100vh',
    marginTop:'-70px',
    fontSize:'50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width:'100%',
    position:'absolute',
};


  return (
    <Model
      section={
        <div className="section">
           <div style={backgroundImageStyle}></div>
        </div>
      }
    />
  )
}

export default LifeHistory
