import React from 'react'
import Model from './Model'
import convoimg from '../img/mad-dogs-sidecar--floor.webp'

function Conversation() {
  const backgroundImageStyle={
    backgroundImage: 
    `url(${convoimg})`,
    height:'100vh',
    marginTop:'-70px',
    fontSize:'50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width:'100%',
    position:'absolute',
};
    return (
        <Model
      section={
        <div className="section">
         <div style={backgroundImageStyle}></div>
        </div>
      }
    />
    )
}

export default Conversation
