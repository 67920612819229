import React from 'react'
import './Content.scss'

function Footer() {
  return (
    <div className="footer">
      <div className="item">
        <h1>header</h1>
      </div>
      <div className="item">
        <h1>header</h1>
      </div>
      <div className="item">
        <h1>header</h1>
      </div>
    </div>
  )
}

export default Footer
