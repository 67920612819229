import React from 'react'
import {motion as m} from "framer-motion";

function Model(props) {
    return (
        <m.div className='Model'
        initial={{opacity:0, y:'-50%'}}
        animate={{opacity:1,y:'0'}}
        transition={{delay:.25, duration:.8, ease:'easeInOut'}}
        exit={{opacity:0, y:'-50%'}}
        >
            <div className='title'>{props.title}</div>
            <div className='section'>{props.section}</div>
            

        </m.div>
    )
}

export default Model
