import React from 'react'
import rangeImg from '../img/mad-dogs-sidecar--floor.webp'
import Model from './Model'
import '../components/Content.scss'
import theVideo from '../img/40f.mp4'

function Range() {
  
  return (
    <div>
      <Model
      section={
        <div className="section">
          <video src={theVideo} autoPlay muted />
        </div>
      }
    />
      <Model
        section={
          
          <div className="section light">
         
            <div className="wrapper">
              <div className="item">
                <div className="left">
                  <img
                    src="https://d2bywgumb0o70j.cloudfront.net/2020/11/11/47d6980f29d8a27732bc5ea6890c87b0_d7fe805f08703501.jpg"
                    alt=""
                  />
                </div>
                <div className="right">
                  <h1>Our philosophy</h1>
                  <p>
                    We pride ourselves in identifying and stocking the world's
                    finest bikes and cycling accessories, and offering
                    unforgettable cycling experiences in some of the most
                    magical destinations on Earth.
                  </p>
                  <p>
                    Our inventory has been hand-picked based on what we believe
                    to be the best you can buy. We specialize in high- quality
                    electric bikes. We carry the most respected and iconic
                    California brands, such as Specialized, Vintage Electric and
                    Super73.
                  </p>
                  <p>
                    We also travel the world to discover and bring you some of
                    the best quality, most innovative 'speciality' bikes in the
                    industry, such as Butchers & Bicycles & TrioBike three-wheel
                    cargo bikes, our hand-crafted made to order European bicycle
                    sidecars and our Leg&Go 8-in-1 conversion kids bike.
                  </p>
                  <p>
                    Everything we sell is superior in quality, design,
                    functionality, craftsmanship and innovation. So whether you
                    are looking for a performance bike for road or trail, a
                    commuter or active/leisure bike, a vintage-inspired or
                    retro-style throttle bike, a folding bike, a cargo bike, a
                    kids bike, or even a super special bicycle sidecar, we have
                    the best in class to meet your needs. Our team is passionate
                    about everything to do with cycling, as well as the
                    communities we cycle in, and we pride ourselves on bringing
                    the newest, most exciting bikes and accessories to market.
                  </p>
                  <p>
                    We also pride ourselves in locating our shops in the heart
                    of the most beautiful cycling communities in California. Our
                    driving force is the opportunity to share the latest and
                    greatest in cycling with our customers, in some of the most
                    scenic cycling destinations on the West Coast. We specialize
                    in electric bikes. We have studied and tested all ebike
                    lines and we know what is out there and we are proud to
                    carry only the best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        }
      />

      <Model
        section={
          <div className="section">
            <div className="wrapper">
              <h1> The benefits you can expect when shopping with us</h1>
              <div className="item">
                <div className="left">
                  <p>
                    Free lifetime adjustments with each bike purchase, plus a
                    complimentary tune-up during your first six months. A
                    complimentary rental bike experience for two (up to $200
                    value) with each bike purchase – can be used at any of our
                    shops anytime by the purchaser or their friends or family
                    50% off unlimited rentals at any of our locations for up to
                    two riders for the first year after your bike purchase,
                    based on availability (blackout dates may apply)*
                  </p>
                </div>
                <div className="right">
                  Free local delivery of all bikes- if you find a bike that you
                  love at one of our shops- purchase it and we can transfer it
                  to one of our other shops for pick up or delivery We stock a
                  large selection of premium quality cycling apparel,
                  accessories, helmets and gear The best rental bikes and guided
                  tours – we offer unforgettable 5- star cycling experiences in
                  California from each of our shops, plus you will not find
                  better quality rental bikes in the areas we serve (check out
                  our consistent five-star reviews on Yelp, Google and Trip
                  Advisor)
                </div>
              </div>
            </div>
          </div>
        }
      />
      <Model
        section={
          <div className="section light">
            <div className="wrapper">
              <div className="item">
                <div className="left">
                  <img
                    src="https://d2bywgumb0o70j.cloudfront.net/2020/11/11/9f66fbb7329956dc417ace46c61f1095_0df411e540344dfa.jpg"
                    alt=""
                  />
                </div>
                <div className="right">
                  <h1>Dogs always welcome!</h1>

                  <p>
                    We launched our first shop in Carmel, which is the most dog
                    friendly town in the US. We are big dog lovers ourselves, so
                    we are excited to be able to offer all customers the
                    opportunity to go cycling with their dogs, along some of the
                    most picturesque coastline California has to offer. We stock
                    dog baskets, sidecars, cargo bikes and even dog goggles!
                  </p>

                  <p>Interested in trying something new?</p>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default Range
