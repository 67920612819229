
import './App.css';

import { Route, Routes } from 'react-router';


import Navbar from './components/Navbar';
import Content from './components/Content';
import Footer from './components/Footer';
import Transitions from './components/Transition';

function App() {
  return (
    
   <div>
    <Transitions/>
    <Navbar/>
    <Content/>
    <Footer/>
    
   </div>

    
  );
}

export default App;
