import React from 'react'
import Description from '../pages/Description'
import Diet from '../pages/Diet'
import Conversation from '../pages/Conversation'
import Range from '../pages/Range'
import { Route } from 'react-router-dom'
import { Routes } from 'react-router'
import { useLocation } from 'react-router-dom'
import {AnimatePresence} from 'framer-motion'


const routes = [
  { path: '/description', name: 'Description', Component: Description },
  { path: '/range', name: 'Range', Component: Range },
  { path: '/diet', name: 'Diet', Component: Diet },
  { path: '/conversation', name: 'Conversation', Component: Conversation },
]
const routeComponents = routes.map(({ path, Component }) => (
  
  <Route path={path} element={<Component />} />
))
function Content() {
    const location = useLocation();
    
  return (
    
    <div className="content">
      
      <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>{routeComponents}</Routes>
      </AnimatePresence>
      
    </div>
  ) 
}

export default Content
