import React, { useEffect } from 'react'
import '../App.css'
import { motion as m } from 'framer-motion'
import { useAnimation } from 'framer-motion'
import theLogo from '../img/madDogs.png'

function Transition() {
  const animationControls = useAnimation()
  async function sequence() {

    await animationControls.start({
      initial:'100%',
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
        
      }
    });
    await animationControls.start({
      y: '100%',
      transition: {
        ease: "easeInOut",
        duration: 1,
        delay:1,
        
      }
    });
    
  }
  useEffect(() => {
    sequence()
  }, [])

  return (
    <div>
    <m.div className='transitionBackground'
      animate={animationControls}
    ><img src={theLogo} alt="logo" className='centerLogo'/></m.div>
  </div>
  )
}

export default Transition
