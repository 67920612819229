import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../img/madDogs.png'
import './Navbar.scss'
import { useState } from 'react'

function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <div className="navbar-container">
      <div className='logoContainer'>
      <img src={logo} alt="logo" className="navLogo" />
      </div>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}
      >
        {
          <svg viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="20"></rect>
            <rect y="30" width="100" height="20"></rect>
            <rect y="60" width="100" height="20"></rect>
          </svg>
        }
      </button>
      <div
        className={
          isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'
        }
      >
      <ul className="nav-menu">
        <div className="wrapper">
          
          <div className="left"  onClick={() => {
          setIsNavExpanded(!isNavExpanded)
        }}>
            <li className="nav-item left">
              <NavLink to="/description" className="nav-link">
                <div className="link-text">SHOP</div>
              </NavLink>
            </li>
            <li className="nav-item left">
              <NavLink to="/range" className="nav-link">
                <div className="link-text">OUR BRANDS</div>
              </NavLink>
            </li>
            <li className="nav-item left">
              <NavLink to="/conversation" className="nav-link">
                <div className="link-text">SPECIALIZED</div>
              </NavLink>
            </li>
            <li className="nav-item left">
              <NavLink to="/diet" className="nav-link">
                <div className="link-text">OUR COMPANY</div>
              </NavLink>
            </li>
          </div>

          <div className="right">
            <li className="nav-item right">
              <NavLink to="/description" className="nav-link">
                <div className="link-text">SERVICE</div>
              </NavLink>
            </li>
            <li className="nav-item right">
              <NavLink to="/range" className="nav-link">
                <div className="link-text">RENTALS</div>
              </NavLink>
            </li>
            <li className="nav-item right">
              <NavLink to="/conversation" className="nav-link">
                <div className="link-text">CONTACTS</div>
              </NavLink>
            </li>
          </div>
        </div>
        
      
      </ul>
      </div>
    </div>
  )
}

export default Navbar
